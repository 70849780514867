<template>
  <div class="potential contractDetail">
    <div ref="navBar">
      <rxNavBar title="合同详情"></rxNavBar>
    </div>
    <p class="application_time">
      签约日期：
      <span>{{ contractInfo.addTime ? contractInfo.addTime : '暂无' }}</span>
    </p>
    <div class="panel withoutMargin">
      <!-- 预定 -->
      <div class="state stateOrange">
        预定
      </div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{ contractInfo.houseAddress }}</p>
      </div>
      <van-row class="info">
        <van-col span="12">
          <p>
            租客：
            <span>{{ contractInfo.userName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            电话：
            <span>{{ contractInfo.mobile }}</span>
          </p>
        </van-col>
      </van-row>
      <!-- 占位样式 -->
      <div class="info"></div>
    </div>
    <div class="tabWrapper">
      <van-tabs
        v-model="tabActive"
        line-width="20"
        line-height="4"
        :lazy-render="false"
        title-active-color="rgb(255,81,45) "
        @click="onClick"
      >
        <van-tab title="租客">
          <!-- 租客内容信息-->
          <span class="contractOrLeaseChange">租客信息</span>
          <div class="panel">
            <van-row type="flex" align="center" class="info">
              <van-col span="5">
                <van-image
                  round
                  width="50"
                  height="50"
                  fit="contain"
                  style="background-color: #e1eaec;"
                  :src="
                    contractInfo.headImgUrl
                      ? contractInfo.headImgUrl
                      : userDefaultHeadImg
                  "
                />
              </van-col>
              <van-col>
                <p class="tenant">
                  <span>{{ contractInfo.userName }}</span>
                  <span class="male" v-if="contractInfo.gender == '1'">男</span>
                  <span class="female" v-if="contractInfo.gender == '0'"
                    >女</span
                  >
                </p>
                <p>{{ contractInfo.mobile }}</p>
              </van-col>
            </van-row>
            <div class="info">
              <p>
                {{
                  contractInfo.certificateTypeValue
                    ? contractInfo.certificateTypeValue
                    : '身份证号'
                }}：
                <span>{{ contractInfo.certificateCode }}</span>
              </p>
              <p>
                职业：
                <span>{{
                  contractInfo.occupation_id
                    ? contractInfo.occupation_id
                    : '暂无'
                }}</span>
              </p>
              <p>
                爱好：
                <span>{{
                  contractInfo.hobby ? contractInfo.hobby : '暂无'
                }}</span>
              </p>
            </div>
            <div class="info" v-if="ownerPhoto.length > 0">
              <van-row gutter="10">
                <van-col
                  v-for="(idcardImg, i) in ownerPhoto"
                  :key="i"
                  :span="7"
                  @click="previewImg(i)"
                >
                  <van-image
                    radius="8"
                    fit="contain"
                    style="background-color: #e1eaec;"
                    height="50"
                    :src="idcardImg"
                  />
                </van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <!-- 合同页签-->
        <van-tab v-if="type == '1'" title="合同">
          <div class="panel refund">
            <van-row class="info">
              <van-col span="24">
                <p>
                  租期:
                  <span>{{ contractInfo.rentTimeLong }}个月</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  租金：
                  <span>{{ contractInfo.rentMoney }}元/月</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  定金：
                  <span>{{ contractInfo.depositMoney }}元/月</span>
                </p>
              </van-col>
            </van-row>
            <div class="info">
              <p class="total_color">
                <span
                  >签约人：<span class="total_color hasPadding"></span
                  >{{ contractInfo.sinName }}</span
                >
                <span class="hasPadding">|</span>
                <span>{{ contractInfo.sinDepartmentName }}</span>
                <span class="floatR small_font">{{
                  contractInfo.applicationTime
                }}</span>
              </p>
              <p class="total_color" v-if="contractInfo.partnerStaff_id">
                <span
                  >合作人：<span class="total_color hasPadding"></span
                  >{{ contractInfo.parUserName
                  }}<span class="hasPadding">|</span
                  >{{ contractInfo.cooperationDepartmentName }}
                </span>
              </p>
              <p v-if="contractInfo.partnerStaff_id">
                <span>合作占比：{{ contractInfo.pardicDescription }}</span>
              </p>
            </div>
          </div>
        </van-tab>
        <!-- 账单 -->
        <van-tab style="margin-left: 10px" title="账单">
          <div class="bill" v-if="bill.length > 0">
            <div v-for="(item, i) in bill" :key="i">
              <div class="billPayTime">
                {{
                  item.billFeeType_id == '2' &&
                  (item.billStatus == 2 || item.billStatus == 3)
                    ? item.billEndTime
                    : item.billPayTime
                }}
              </div>
              <div class="bill_panel">
                <van-row class="bill_info" type="flex" align="center">
                  <!-- 四种状态 待确认billStatus 账单状态(0 应收=应支 1已收=已支 2 应付=应收 3 已付=已收 4 停付) 作为业主 对应的是海米  应该支付给海米（应支） 134 金额浅色-->
                  <div class="laber " v-if="item.billStatus == 0">
                    <div class="laberText  ">应收</div>
                  </div>
                  <div class="laber " v-if="item.billStatus == 1">
                    <div class="laberText ">已收</div>
                  </div>
                  <div class="laber " v-if="item.billStatus == 2">
                    <div class="laberText ">应支</div>
                  </div>
                  <div class="laber " v-if="item.billStatus == 3">
                    <div class="laberText ">已支</div>
                  </div>
                  <div class="laber " v-if="item.billStatus == 4">
                    <div class="laberText ">停付</div>
                  </div>
                  <van-col span="19">
                    <p>
                      {{ item.dictionaryTitle }}
                      <span>{{
                        item.billDescription
                          ? '(' + item.billDescription + ')'
                          : ''
                      }}</span>
                    </p>
                    <p class="small">
                      <span>费用周期：</span>
                      <span>{{ item.billBeginTime }}</span>
                      <span class="hasPadding">至</span>
                      <span>{{ item.billEndTime }}</span>
                    </p>
                  </van-col>

                  <van-col span="5">
                    <p
                      :class="
                        item.billStatus == '1' ||
                        item.billStatus == '3' ||
                        item.billStatus == '4'
                          ? 'rentPriceA'
                          : ' total_color bill_money'
                      "
                    >
                      {{ item.totalAmount }}元
                    </p>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <!--空状态图-->
          <common-empty v-if="bill.length == 0"></common-empty>
        </van-tab>
      </van-tabs>
    </div>
    <div class="bottom-fixed">
      <div class="bottom_btn">
        <van-button size="large" class="btn" @click="showMobile()"
          >联系管家</van-button
        >
      </div>
    </div>

    <dial-mobile
      :ownerMobile="temporaryOwnerMobile"
      :userName="temporaryOwnerName"
      :dialogTitle="dialogDTitle"
      :mobileShow="isMobileShow"
      @hideDialog="hideDialog"
    >
    </dial-mobile>
  </div>
</template>
<script>
import BScroll from 'better-scroll'
import {
  NavBar,
  Tab,
  Tabs,
  Row,
  Col,
  Divider,
  Tag,
  Image as VanImage,
  Button,
  ImagePreview,
  Popup,
} from 'vant'

import { queryPerContractBills } from '../../getData/getData'
import { checkAndroid, checkIOS, responseUtil } from '../../libs/rongxunUtil'
import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
import rxNavBar from '../../components/rongxun/rx-navBar/navBar'
/********判断H5页面是否在web容器中 start*********/
function openInWebview() {
  /* 返回true或false; */
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    // 微信浏览器判断
    return false
  } else if (ua.match(/QQ/i) == 'qq') {
    // QQ浏览器判断
    return false
  } else if (ua.match(/WeiBo/i) == 'weibo') {
    return false
  } else {
    if (ua.match(/Android/i) != null) {
      return ua.match(/browser/i) == null
    } else if (ua.match(/iPhone/i) != null) {
      return ua.match(/safari/i) == null
    } else {
      return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null
    }
  }
}
/********判断H5页面是否在web容器中 start*********/
export default {
  data() {
    return {
      dialogDTitle: '联系管家',
      //拨打电话弹窗
      isMobileShow: false,
      temporaryOwnerMobile: '',
      temporaryOwnerName: '',
      type: '',
      //业主合同
      contractInfo: {},
      userDefaultHeadImg: require('../../assets/images/default-headImg.jpg'),
      ownerPhoto: [], //业主身份图片信息
      title: '',
      tabActive: 0,
      // 租客
      tenant: {
        headPortrait: 'https://img.yzcdn.cn/vant/cat.jpeg',
      },
      //物业交割信息
      bill: [],
    }
  },
  created() {
    this.contractInfo = this.$route.query.item
    this.type = this.$route.query.type
    if (this.contractInfo.frontOfCardPath) {
      this.ownerPhoto.push(this.contractInfo.frontOfCardPath)
    } else {
      this.ownerPhoto.push(require('../../assets/images/default_img_4.png'))
    }
    if (this.contractInfo.reverseOfCardPath) {
      this.ownerPhoto.push(this.contractInfo.reverseOfCardPath)
    } else {
      this.ownerPhoto.push(require('../../assets/images/default_img_5.png'))
    }
    if (this.contractInfo.holdingOfCardPath) {
      this.ownerPhoto.push(this.contractInfo.holdingOfCardPath)
    } else {
      this.ownerPhoto.push(require('../../assets/images/default_img_3.png'))
    }
  },
  methods: {
    //返回上一层
    leftReturn() {
      this.$router.go(-1)
    },
    //拨打电话
    showMobile() {
      let that = this
      that.temporaryOwnerMobile = this.contractInfo.renterStfMobile
      that.temporaryOwnerName = this.contractInfo.staffName
      that.isMobileShow = !that.isMobileShow
    },
    //关闭拨打电话弹窗
    hideDialog() {
      let that = this
      that.isMobileShow = false
    },
    previewImg(start) {
      ImagePreview({
        images: this.ownerPhoto,
        startPosition: start,
        onClose() {},
      })
    },
    //类型选择变换
    onClick(name, title) {
      //console.log(title)
      if (title == '账单') {
        this.queryPerContractBill()
      }
    },

    //加载预定合同
    queryPerContractBill() {
      let that = this
      let initData = {}
      let perContractId = that.contractInfo.id
      initData.perContractId = perContractId
      initData.currentPage = '1'
      initData.numberPage = '200'
      queryPerContractBills(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.bill = response.data.data.data || []
        })
      })
    },
  },
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tag.name]: Tag,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [BScroll.name]: BScroll,
    [ImagePreview.name]: ImagePreview,
    [Popup.name]: Popup,
    rxNavBar,
    dialMobile,
  },
}
</script>
<style lang="less" scoped>
@redius: 8px;
@spanColor: rgba(102, 102, 102, 1);

.tu_hide {
  display: none;
  width: 19px;
  height: 19px;
  float: right;
}

.tu_show {
  display: block;
  width: 19px;
  height: 19px;
  float: right;
}

.unpay {
  width: 20px;
  height: 20px;
  float: right;
}

.potential {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;

  p {
    margin: 0;
  }

  span {
    &.hasPadding {
      padding: 0 5px;
    }
  }

  .panel {
    /*width: 345px;*/
    width: 92%;
    margin: 15px auto;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    padding: 15px;
    box-sizing: border-box;

    &.withoutMargin {
      margin: 3px auto;
      margin-bottom: 20px;
    }

    p {
      margin: 6px 0;
      font-weight: bolder;

      span {
        font-weight: normal;
        color: rgb(151, 151, 151);
      }
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: bold;
      height: 31px;
      line-height: 33px;
      width: 60px;
      display: block;
      background-color: rgb(163, 153, 153);
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;

      &.stateGreen {
        background-image: linear-gradient(to right, #66cb94, #28d3b0);
      }

      &.stateRed {
        background-image: linear-gradient(to right, #e02020, #ff431b);
      }

      &.stateGray {
        background-image: linear-gradient(to right, #999999, #999999);
      }

      &.stateOrange {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
      }
    }

    .title {
      font-weight: bolder;
      color: rgb(0, 0, 0);
      font-size: 15px;
      /*padding: 20px 15px 15px;*/
      border-bottom: 1px solid rgb(250, 250, 250);

      span {
        font-weight: normal;
        color: rgba(153, 153, 153);
      }
    }

    .info {
      /*padding: 15px;*/
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgb(250, 250, 250);

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }

      .tenant {
        font-size: 18px;
        display: flex;
        align-items: center;
        .male {
          font-size: 12px;
          color: rgba(86, 155, 236, 1);
          margin-left: 10px;
        }

        .female {
          font-size: 12px;
          color: rgba(255, 140, 219, 1);
          margin-left: 10px;
        }
      }

      .DO_link {
        float: right;
        color: rgba(86, 155, 236, 1);
        cursor: pointer;
        font-size: 12px;
      }
    }

    &.refund {
      p {
        line-height: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .span-description {
    font-size: 15px;
    font-weight: normal;
    color: rgba(153, 153, 153);
  }

  .bill {
    margin-top: 15px;

    .bill_title {
      font-size: 12px;
      background-color: rgb(241, 241, 241);
      color: rgb(155, 155, 155);
      padding: 1px 15px;
    }

    .bill_panel {
      background-color: rgb(255, 255, 255);

      .bill_info {
        position: relative;

        /*padding: 15px;*/
        padding: 0 15px;
        border-bottom: 1px solid rgb(250, 250, 250);

        .img_icon {
          position: absolute;
          top: 0;
          right: -6px;
        }

        &:nth-last-child {
          border-bottom: none;
        }

        .bill_money {
          font-size: 15px;

          &.unreceived {
            opacity: 0.3;
          }
        }

        p {
          font-weight: bolder;
          font-size: 14px;
          padding-top: 11px;

          &:last-of-type {
            padding-bottom: 10px;
          }
        }

        span {
          font-weight: normal;
          color: rgb(151, 151, 151);
        }
      }
    }
  }

  .billPayTime {
    color: rgb(151, 151, 151);
    height: 18px;
    line-height: 18px;
  }

  .application_time {
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136, 136, 136);
    padding: 10px 0 5px;
  }

  .tabWrapper {
    width: 100%;
    overflow: hidden;
    margin-bottom: 95px;
  }

  .bottom_btn {
    margin-left: 20px;
    /*padding:10px 15px;*/
    /*margin-left: 4.25rem;*/
    margin-left: 5.55rem;

    .btn {
      width: 120px;
      margin-top: 10px;
      border-radius: @redius;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(
        left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      color: rgb(255, 255, 255);
      font-size: 16px;
    }
  }

  // 图片墙
  .swiper_show {
    width: 325px;
    position: relative;
    margin: 15px 10px;
    height: 75px;

    .content {
      position: absolute;
      display: flex;
      justify-content: space-around;
      left: 0px;

      .imgWrap {
        margin-right: 10px;
      }
    }

    .num {
      position: absolute;
      left: 4px;
      top: 6px;
      font-size: 10px;
      background-color: rgb(0, 0, 0);
      opacity: 0.5;
      color: rgb(255, 255, 255);
      padding: 2px 6px;
      display: inline-block;
      border-radius: 10px;
      font-weight: bolder;
      line-height: 10px;
    }
  }

  // 时间线
  .approve {
    margin-top: 20px;

    .time_line {
      .item {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .left {
          width: 20px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          /*margin-right: 5px;*/

          .line {
            width: 1px;
            background-color: rgb(255, 75, 39);
            position: absolute;

            &.line_top {
              top: 0px;
              height: ceil(50%-8px);

              &.first_top {
                background: -webkit-linear-gradient(
                  top,
                  rgba(255, 255, 255, 1),
                  rgba(255, 93, 59, 1)
                );
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(
                  bottom,
                  rgba(255, 255, 255, 1),
                  rgba(255, 93, 59, 1)
                );
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(
                  bottom,
                  rgba(255, 255, 255, 1),
                  rgba(255, 93, 59, 1)
                );
                /* Firefox 3.6 - 15 */
                background: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 1),
                  rgba(255, 93, 59, 1)
                );
              }
            }

            &.line_bottom {
              bottom: 0;
              height: ceil(50%+10px);
            }
          }

          .point {
            position: relative;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            border: 1px solid rgb(255, 75, 39);
            transform: translateY(-50%);
            z-index: 99;
            background-color: rgb(255, 255, 255);

            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: rgb(255, 75, 39);
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }
        }

        .right {
          width: 100%;
          padding-bottom: 20px;

          .approve_panel {
            margin: 0 15px;
            border-radius: @redius;
            background-color: rgb(255, 255, 255);
            padding: 10px;

            p {
              padding-top: 10px;
              font-weight: bolder;

              &:last-of-type {
                padding-bottom: 10px;
              }

              span {
                font-weight: normal;
                color: rgb(82, 82, 82);
              }
            }
          }
        }
      }
    }
  }

  .small_font {
    font-size: 12px !important;
  }

  .floatR {
    float: right !important;
  }

  .total_color {
    color: rgba(255, 93, 59, 1) !important;
  }
}

/* 未收图标 */
.laber {
  width: 0px;
  height: 0px;
  border-top: 0px solid #fe8d68;
  border-right: 44.6px solid #fe8d68;
  border-bottom: 44.6px solid transparent;
  border-left: 30px solid transparent;
  position: absolute;
  top: 0px;
  right: 0px;
}

/* 旋转文字 */
.laberText {
  width: 33px;
  height: 47px;
  transform: rotate(45deg);
  font-size: 12px;
  color: #ffffff;
}

.rentPriceA {
  color: rgba(252, 213, 204, 1);
  font-weight: 500;
}

/*免租期样式*/
.freeTime {
  width: 345px;
  margin: 15px 15px 15px 15px;
  height: auto;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
}

.totalTime {
  width: 345px;
  margin: 0 15px 15px 15px;
  height: 45px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
}

.freeOne {
  height: auto;
  width: 100%;
  border-bottom: 1px solid #f8f8f8;
  overflow: hidden;
}

.freeOne-title {
  margin: 5px 0 5px 20px;
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.freeOne-title span {
  color: black;
  font-weight: normal;
}

.freeOne-time {
  margin: 5px 0 5px 20px;
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.freeOne-time span {
  color: black;
  font-weight: normal;
}

.totalTitle {
  margin-left: 20px;
  font-weight: bold;
  font-size: 15px;
  height: 20px;
  line-height: 22px;
  width: fit-content;
}

.totalLine {
  width: 1px;
  height: 15px;
  margin: 0 8px;
  background-color: gray;
}

.totalDays {
  width: 120px;
  height: 20px;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
  color: red;
}

.totalDesc {
  color: gray;
  text-align: left;
  font-size: 15px;
  height: 20px;
  line-height: 22px;
  width: fit-content;
  margin-left: 100px;
}

/*交割单标题文字*/
.contractOrLeaseChange {
  display: inline-block;
  color: red;
  font-weight: bold;
  margin: 15px 0 0 15px;
  font-size: 15px;
}

//电子合同
.electric-contract {
  float: left;
  height: 60px;
  width: 70px;
  margin-top: 0.25rem;
  margin-left: 35px;
  font-size: 14px;
}

//电子合同图片
.electric-contract-photo {
  margin-top: 5px;
  margin-left: 16px;
}

.bottom-fixed {
  position: fixed;
  border-top: 1px solid rgb(243, 243, 243);
  width: 100%;
  height: 1.86667rem;
  bottom: 0px;
  background-color: white;
}

.bz {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px;
}
</style>
